/**
Crayola Colors
Prefix with color and use coolors.co or "name that color" for names.
https://coolors.co
http://chir.ag/projects/name-that-color/
*/

// Theme colors
// https://coolors.co/18262f-213542-9cc773-939393-777674
$color-dark-gunmetal: #18262f;
$color-gunmetal: #213542;
$color-pistachio: #9CC773;
$color-spanish-grey: #939393;
$color-sonic-silver: #777674;

// Status theme colors
// https://coolors.co/24b7f2-1bb53f-f2c224-f27543-f24946
$color-spiro-disco-blue: #24B7F2;
$color-dark-pastel-green: #1BB53F;
$color-saffron: #F2C224;
$color-tart-orange: #F24946;

// Greyscale
// https://coolors.co/434a54-656d78-aab2bd-ccd0d9-e6e9ee
$color-outer-space: #434A54;
$color-paynes-grey: #656D78;
$color-silver-chalice: #AAB2BD;
$color-light-gray: #CCD0D9;
$color-glitter: #E6E9EE;
$color-ghost-white: #F6F7FB; // TODO color contast too low? this will just render as white on most uncalibrated monitors (modal titles, for one, are indistinguishable from the modal body)
$color-white: #fff;

// Semantic color names:
$color-primary: $color-pistachio;
$color-secondary: $color-gunmetal;
$color-positive: $color-dark-pastel-green;
$color-info: $color-spiro-disco-blue;
$color-warning: $color-saffron;
$color-alert: $color-tart-orange;

$color-body-background: $color-ghost-white;

/**
Typography defaults
*/
$font-size-base: 1.6rem;
$font-size-extra-small: 1.2rem;
$font-size-small: 1.4rem;
$font-size-regular: $font-size-base;
$font-size-large: 2.2rem;
$font-size-x-large: 3.0rem;
$font-size-xx-large: 4.5rem;

$font-family-base: 'Open Sans', 'sans-serif';
$font-family-text: $font-family-base;
$font-family-alternative: 'Raleway', 'sans-serif';
$font-family-heading: 'Raleway', 'sans-serif';
$font-family-monospace: 'Menlo', 'Monaco', 'Courier New', 'Courier', 'monospace';
$font-family-code: $font-family-monospace;

$font-weight-normal: 400;
$font-weight-bold: 700;

/**
Z-index
*/
$zindex-dropdown:     1000;
$zindex-sticky:       1020;
$zindex-fixed:        1030;
$zindex-notification: 1040;
$zindex-overlay:      1050;
$zindex-spinner:      1060;
$zindex-modal:        1070;

/**
Form defaults
*/
$input-color: $color-outer-space;
$input-color-placeholder: $color-silver-chalice;
$input-color-border: $color-silver-chalice;
$input-color-border-focus: $color-paynes-grey;
$input-color-background: $color-white;
$input-color-background-disabled: $color-glitter;
$color-disabled: $color-light-gray;

$label-color: $color-paynes-grey;

$input-border: 1px solid $input-color-border;
$input-border-radius: 3px;

$input-padding-y: 0.8rem;
$input-padding-x: 1rem;
$input-padding: $input-padding-y $input-padding-x;

// TODO move vars that are used only once
$button-border-radius: 20px;
$button-padding-y: 1.1rem;
$button-padding-x: 3rem;

// responsive media query breakpoints:
$device-sm: 768px;
$device-md: 992px;
$device-lg: 1200px;
