@import "../vendor/kodus/admin-ui/sass/vars";

.user-ui-login {
  width: 100%;
  height: 100%;

  &__forgot-password {
    margin-right: 1rem;
    font-style: italic;
    font-size: $font-size-small;
  }

  @media (min-width: $device-sm) {
    display: flex;
    align-items: center;
    justify-content: center;

    &__box {
      min-width: 50rem;
    }
  }
}
